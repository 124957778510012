import { createGlobalStyle } from "styled-components";

// https://fluidtypography.com/
// h1 42 -> 58 / 1.2 -> 1.1125
// h2 26 -> 36 / 1.22222 -> 1.2
// h3 18 -> 26 / "
// h4 16 -> 12 / 1.2 1.2
// p  14 -> 18 / 1.2 1.2

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #434343 #000000;
  }

  .keen-slider:not([data-keen-slider-disabled]) {
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
  }
  .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 100%;
  }
  .keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
    flex-direction: row-reverse;
  }
  .keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
    flex-wrap: wrap;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border-radius: 32px;
    @media(max-width: 768px) {
      border-radius: 17px;
      margin-top: 2rem;
    }
  }

  body.home {
    background-image: url("/odin.jpg");
    background-position: top center;
    background-size: cover;
  } 

  .banner-link:last-of-type {
    background-color: #fff;
    padding-bottom: 150px;
    @media(max-width: 768px) {
      padding-bottom: 50px;
    }
  }

  .visible:last-of-type {
    hr {
      display: none;
    }
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  hr {
    border-top: 2px solid #000;
    color: #fff;
  }

  p:empty {
    min-height: 1rem;
  }

  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    width: 10px;
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #000000;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #000000;
  }

  *::-webkit-scrollbar-track:active {
    background-color: #000000;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #434343;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #555555;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: #666666;
  }

  body main {
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
  }

  body.open {
    overflow: hidden;
  }

  body.open main {
    opacity: 0;
  }

  img.main-image {
    margin-top: 87px;
    border-radius: 20px;
    width: 100%;
    max-width: 60vw;
    @media(max-width: 768px) {
      max-width: calc(100vw - 2rem);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .results {
    h3.thanks {
      text-align: center;
      max-width: 900px;
      margin-bottom: 75px;
    }
    .flex {
      display: flex;
      justify-content: center;
      column-gap: 40px;
      margin-bottom: 40px
    }
    .rider-flex {
      display: flex;
      align-items: center;
      column-gap: 40px;
    }
    .rider-flex img {
      transition: all 0.2s ease-in-out;
    }
    .rider-flex img.reversed {
      transform: rotate(180deg);
    }
    button {
      font-size: 22px;
      padding: 25px 64px;
      border: 2px solid #000;
      background-color: #fff;
      color: #000;
      border-radius: 120px;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
    button.active {
      background-color: #000;
      color: #fff;
    }
    button:hover{
      background-color: #fafafa;
    }
    button.active:hover {
      background-color: #111;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    tr:nth-child(even) {
      background: #F0F0F0;
    }
    th {
      cursor: pointer;
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      div {
        white-space: nowrap;
      }
    }
    th.route {
      cursor: default;
    }
    td {
      white-space: nowrap;
    }
    td, th {
      padding: 34px;
    }
    td img {
      width: 75px;
      border-radius: 75px;
    }
    img.rider-big {
      width: 380px;
      height: 380px;
      border-radius: 50%;
      object-fit: cover;
    }
    img.rider {
      width: 75px;
      height: 75px;
      object-fit: cover;
      object-position: center center;
      border-radius: 50%;
      background-color: #f0f0f0;
    }
    .box {
      padding-top: 90px;
      padding-bottom: 90px;
      background-color: #f0f0f0;
      border-radius: 40px;
      margin-bottom: 40px;
    }
    .intro {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }
    .sorting-hat {
      display: none;
    }
    .rider-mobile {
      display: none;
    }
    @media (max-width: 768px) {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
      .sorting-hat-closed .flex {
        display: none;
      }
      .sortage img.open {
        transform: rotate(0deg);
      }
      .sortage img.closed {
        transform: rotate(180deg);
      }
      .rider-desktop {
        display: none;
      }
      img.open {
        transform: rotate(180deg);
      }
      img.closed {
        transform: rotate(0deg);
      }
      div.closed {
        display: none;
      }
      div.open {
        display: block;
      }
      div.rider-mobile {
        display: flex;
        flex-direction: column;
        position: relative;
        flex: 1 0;
      }
      div.rider-mobile img {
        width: 16px;
        position: absolute;
        right: 6px;
        top: 6px;
      }
      .rider-flex {
        display: flex;
        column-gap: 16px;
        align-items: flex-start;
      }
      img.rider {
        object-fit: cover;
        max-width: 70px;
        max-height: 70px;
        border-radius: 50%;
        background-color: #f0f0f0;
      }
      button {
        font-size: 16px;
        padding: 14px 12px;
      }
      table {
        max-width: 100vw;
      }
      th {
        display: none;
      }
      tr {
        display: flex;
        flex-direction: column;
        max-width: calc(100vw - 64px);
      }
      span.ridername {
        max-width: 300px;
      }
      td {
        padding: 16px;
      }
      td.desktop {
        display: none;
      }
      .table-container {
        width: 100%;
      }
      .box {
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f0f0f0;
        border-radius: 40px;
        margin-bottom: 40px;
      }
      .box h4 {
        max-width: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      .sortage {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid #DEDEDE;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 2px solid #DEDEDE;
        margin-bottom: 32px;
      }
      .sorting-hat {
        display: block;
        margin-top: 42px;
        margin-bottom: 42px;
      }
      .flex {
        column-gap: 16px;
        margin-bottom: 14px;
      }
      .flex button {
        width: 100%;
      }
      .flex-outer {
        flex-direction: column;
      }
      .grid {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  ul.snipcart-featured-payment-methods__list {
    display: block;
    margin: 0 auto;
    background-image: url('/payment-methods.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    min-height: 100px;
    max-width: 400px;
    margin-top: 1rem;
    li {
      display: none;
    }
  }

  .snipcart-cart-button, .snipcart-cart-button--highlight {
    background-image: none !important;
    background-color: #fff !important;
    color: #000 !important;
    border: 2px solid #000 !important;
    text-transform: uppercase;
    border-radius: 38px;
  }

  .snipcart-cart-header__option, .snipcart-cart-header__close-button {
    svg {
      stroke: #000;
      transform: scale(1.2);
    }
  }

  .snipcart__actions--link {
    color: #555 !important;
  }

  :root {
    --text: #000000;
    --white: #fff;
    --border: #434343;
  }

  ::-moz-selection { /* Code for Firefox */
    background: rgba(50,50,50, 0.75);
  }

  ::selection {
    background: rgba(50,50,50, 0.75);
  }

  * {
    margin: 0;
    font-family: Montserrat, sans-serif;
  }

  a:focus {
    outline: 0;
  }



  html {
    scroll-behavior: smooth;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }

  html.lenis {
    height: auto;
  }

  html,
  body {
    height: 100%;
    background-color: #000;
    max-width: 100vw;
  }

  body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    position: relative;
  }

  main {
    min-height: calc(100vh - 781px);
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }


  h1 {
    font-family: Montserrat, sans-serif;
    font-size: 90px;
    line-height: 100px;
    @media(max-width: 768px) {
      font-size: 46px;
      line-height: 52px;
    }
  }

  h2 {
    font-family: Montserrat, sans-serif;
    font-size: 52px;
    line-height: 72px;
    font-weight: 400;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 768px) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  h3 {
    font-family: Montserrat, sans-serif;
    font-size: 34px;
    line-height: 44px;
    max-width: 1100px;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  p {
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    line-height: 32px;
    @media(max-width: 768px) {
      font-size: 15px;
      line-height: 22px;
    }
  }

  #root,
  #__next {
    isolation: isolate;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-color: transparent;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid var(--border);
    -webkit-text-fill-color: var(--white);
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
    outline: 0;
  }

  .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
  .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

`;

export default GlobalStyle;
