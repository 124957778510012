import React, { useEffect } from "react";
import { styled } from "styled-components";
import { Link } from "gatsby";
import Logo from "../../images/logo.svg";
import Menu from "../../images/menu.svg";
import Popover from "../popover";
import Basket from "../basket";

const Outer = styled.div``;

const Login = styled.div`
  display: flex;
  align-items: center;
  a {
    color: #fff;
    display: flex;
    text-decoration: none;
    border-bottom: none;
  }
`;

const Container = styled.nav`
  position: relative;
  background-color: transparent;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  transition: all 0.25s ease-in-out;
  &.open {
    background-color: #000;
  }
  &.closed {
    background-color: transparent;
  }
  color: #fff;
  span {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    max-width: 0px;
    overflow-x: hidden;
    opacity: 0;
    transform: translateY(-16px);
    &.open {
      opacity: 1;
      max-width: 1000px;
      overflow-x: visible;
      display: inline-block;
      transform: translateY(0px);
    }
  }
`;

const Inner = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.56) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  min-height: 204px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 54px;
  padding-right: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    min-height: 100px;
    padding-left: 24px;
    padding-right: 24px;
  }
  img.logo {
    @media (max-width: 768px) {
      width: 120px;
    }
  }
`;

const Right = styled.div`
  display: flex;
  column-gap: 47px;
  @media (max-width: 768px) {
    column-gap: 20px;
  }
`;

const Burger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 0;
  img {
    transform: translateY(-1px);
  }
`;

const Nav = ({ setOpen, open }) => {
  useEffect(() => {
    if (window !== undefined) {
      const handleEsc = (event) => {
        if (event.keyCode === 27) {
          setOpen(false);
        }
      };
      window.addEventListener("keydown", handleEsc);
      return () => {
        window.removeEventListener("keydown", handleEsc);
      };
    }
  }, []);

  return (
    <>
      <Container className={open ? "open" : "closed"}>
        <Inner>
          <Link to="/">
            <img
              className="logo"
              aria-label="Pan Celtic Race Series"
              src={Logo}
              alt="Pan Celtic Race Series"
            />
          </Link>
          <Right>
            <Burger
              className={open ? "open" : "closed"}
              onClick={() => setOpen(!open)}
            >
              <img
                className="menu-toggle"
                src={Menu}
                alt="Menu"
                aria-haspopup="true"
                aria-controls="menu"
              />{" "}
              {open ? (
                <span className="open">CLOSE</span>
              ) : (
                <span className="open">MENU</span>
              )}
            </Burger>
            <Basket open={open} />
            <Login>
              <a href="https://riders.pancelticrace.com/" target="_blank">
                <img
                  style={{ width: "16px", marginRight: "16px" }}
                  src="data:image/svg+xml,%3c?xml%20version='1.0'%20encoding='UTF-8'?%3e%3csvg%20id='Layer_2'%20data-name='Layer%202'%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2081.93%2092.03'%3e%3cdefs%3e%3cstyle%3e%20.cls-1%20{%20fill:%20%23fff;%20stroke-width:%200px;%20}%20%3c/style%3e%3c/defs%3e%3cg%20id='Layer_1-2'%20data-name='Layer%201'%3e%3cg%3e%3ccircle%20class='cls-1'%20cx='40.96'%20cy='23'%20r='23'/%3e%3cpath%20class='cls-1'%20d='M81.93,92.03c0-23.83-18.34-43.16-40.96-43.16S0,68.2,0,92.03h81.93Z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e"
                  alt=""
                />
                LOGIN
              </a>
            </Login>
          </Right>
        </Inner>
      </Container>
      <Popover setOpen={setOpen} open={open} />
    </>
  );
};

export default Nav;
